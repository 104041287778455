var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "justify-center d-flex mt-n3",
    attrs: {
      "fluid": ""
    }
  }, [_vm.functions.hasSufficientRights(_vm.constants.Rights.WORKING_DAYS_PLANNED_READ) ? _c('WorkingDaysPlanned') : _vm._e(), _c('v-divider', {
    staticClass: "mx-4",
    attrs: {
      "vertical": ""
    }
  }), _vm.functions.hasSufficientRights(_vm.constants.Rights.PRODUCT_INCREMENT_INTERVAL_READ) ? _c('ProductIncrements') : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }