
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { mdiSync, mdiPlus } from '@mdi/js'
import { cloneDeep, isEqual } from 'lodash-es'

import { useNotify } from '@/store'

import CommonTableView from '@/components/common/CommonTableView.vue'

import { useFilter } from '@/composables/useFilter'

import { setSort } from '@/utils/manipulateTableSort'
import { hasSufficientRights } from '@/utils/hasSufficientRights'
import { isYear } from '@/utils/validation'

import { useDeleteWorkingDaysPlanned, useGetWorkingDaysPlanned } from '@/api/workingDaysPlanned'

import { NewWorkingDaysPlanned, WorkingDaysPlanned } from '@/api/types/workingDaysPlanned'
import { Rights } from '@/api/types/right'
import { FilterDataTypes, FilterField, OptionalFiltersParam } from '@/composables/types/useFilter'

export default defineComponent({
  name: 'WorkingDaysPlanned',
  components: {
    CommonTableView,
    AddEditWorkingDaysPlannedDialog: () =>
      import('@/views/planning/views/timePlanning/components/AddEditWorkingDaysPlannedDialog.vue'),
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
  },
  setup(_, { root }) {
    const TABLE_HEADERS = [
      {
        text: root.$t('planning.timePlanning.components.workingDaysPlanned.table.col.title.month'),
        value: 'month',
        sortable: true,
      },
      {
        text: root.$t('planning.timePlanning.components.workingDaysPlanned.table.col.title.daysPlanned'),
        value: 'daysPlanned',
        sortable: true,
      },
      {
        text: root.$t('planning.timePlanning.components.workingDaysPlanned.table.col.title.actions'),
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const { addNotification } = useNotify()

    const {
      exec: getWorkingDaysPlanned,
      data: workingDaysPlanned,
      isLoading,
      paginationResponse,
    } = useGetWorkingDaysPlanned()

    const isEditMode = ref(false)

    const isAddEditModalOpen = ref(false)

    const workingDaysPlannedToEdit = ref<null | WorkingDaysPlanned | NewWorkingDaysPlanned>(null)

    function onWorkingDaysPlannedEdit(_, { item: workingDaysPlanned }: { item: WorkingDaysPlanned }): void {
      if (hasSufficientRights(Rights.WORKING_DAYS_PLANNED_UPDATE)) {
        isEditMode.value = true

        isAddEditModalOpen.value = true

        workingDaysPlannedToEdit.value = { ...workingDaysPlanned, updateExistingWorkingHours: false }
      }
    }

    const isDeleteModalOpen = ref(false)
    const workingDaysPlannedToDelete = ref<null | WorkingDaysPlanned>(null)

    const { deleteWorkingDaysPlanned: deleteWorkingDaysPlannedXhr } = useDeleteWorkingDaysPlanned()

    async function deleteWorkingDaysPlanned(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await deleteWorkingDaysPlannedXhr(workingDaysPlannedToDelete.value!.id)

      addNotification({
        text: root.$t('planning.timePlanning.components.workingDaysPlanned.delete.message') as string,
        type: 'success',
      })

      isDeleteModalOpen.value = false

      debouncedCb.value()
    }

    function onWorkingDaysPlannedDelete(workingDaysPlanned: WorkingDaysPlanned): void {
      workingDaysPlannedToDelete.value = cloneDeep(workingDaysPlanned)

      isDeleteModalOpen.value = true
    }

    function onCloseAddEditDialog(): void {
      isAddEditModalOpen.value = false

      workingDaysPlannedToEdit.value = null

      isEditMode.value = false
    }

    const FILTER_FIELDS = ref<FilterField[]>([
      {
        key: 'year',
        value: null,
        label: root.$t('persons.filters.name'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
    ])

    const { debouncedCb, vuetifyTableOptions, paginationParams, filterFieldsObject, isInit } = useFilter(
      FILTER_FIELDS.value as FilterField[],
      init
    )
    setSort(vuetifyTableOptions, { by: 'month', desc: true })

    watch(
      filterFieldsObject,
      (newVal, oldVal) => {
        isInit.value = false

        if (!isEqual(newVal, oldVal)) {
          debouncedCb.value()
          vuetifyTableOptions.value.page = 1
        }
      },
      { deep: true }
    )

    const totalWorkingDaysPlanned = computed(() => paginationResponse.value.totalElements)

    async function init(filterFieldsObject?: OptionalFiltersParam): Promise<void> {
      await getWorkingDaysPlanned({ params: { ...paginationParams.value, ...filterFieldsObject } })
    }

    return reactive({
      icons: {
        mdiSync,
        mdiPlus,
      },
      constants: {
        Rights,

        TABLE_HEADERS,
      },
      state: {
        workingDaysPlanned,
        isLoading,

        isDeleteModalOpen,

        isEditMode,
        isAddEditModalOpen,
        workingDaysPlannedToEdit,

        FILTER_FIELDS,

        totalWorkingDaysPlanned,
        vuetifyTableOptions,
      },
      functions: {
        isYear,

        debouncedCb,

        getWorkingDaysPlanned,

        deleteWorkingDaysPlanned,

        hasSufficientRights,
      },
      listeners: {
        onCloseAddEditDialog,
        onWorkingDaysPlannedEdit,
        onWorkingDaysPlannedDelete,
      },
    })
  },
})
