
import { defineComponent, reactive } from '@vue/composition-api'

import WorkingDaysPlanned from '@/views/planning/views/timePlanning/components/WorkingDaysPlanned.vue'
import ProductIncrements from '@/views/planning/views/timePlanning/components/ProductIncrements.vue'

import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { Rights } from '@/api/types/right'

export default defineComponent({
  name: 'TimePlanning',
  components: {
    WorkingDaysPlanned,
    ProductIncrements,
  },
  setup: () => {
    return reactive({
      constants: {
        Rights,
      },
      functions: {
        hasSufficientRights,
      },
    })
  },
})
