import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'
import { PromiseType } from '@/utils/types/PromiseType'
import {
  NewWorkingDaysPlanned,
  UseCreateWorkingDaysPlanned,
  UseDeleteWorkingDaysPlanned,
  UseGetWorkingDaysPlanned,
  UseUpdateWorkingDaysPlanned,
  WorkingDaysPlanned,
  WorkingDaysPlannedId,
} from './types/workingDaysPlanned'

const VERSION = 'v1'
const RESOURCE = 'working-days-planned'

function useGetWorkingDaysPlanned(): UseGetWorkingDaysPlanned {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetWorkingDaysPlanned['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
  })
  return axios
}

function useCreateWorkingDaysPlanned(): UseCreateWorkingDaysPlanned {
  const axios = useAxios<PromiseType<ReturnType<UseCreateWorkingDaysPlanned['createWorkingDaysPlanned']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createWorkingDaysPlanned(data: NewWorkingDaysPlanned): Promise<WorkingDaysPlanned> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createWorkingDaysPlanned,
  }
}

function useUpdateWorkingDaysPlanned(): UseUpdateWorkingDaysPlanned {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateWorkingDaysPlanned['updateWorkingDaysPlanned']>>>({
    method: 'PUT',
  })

  function updateWorkingDaysPlanned(id: WorkingDaysPlannedId, data: WorkingDaysPlanned): Promise<WorkingDaysPlanned> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateWorkingDaysPlanned,
  }
}

function useDeleteWorkingDaysPlanned(): UseDeleteWorkingDaysPlanned {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteWorkingDaysPlanned['deleteWorkingDaysPlanned']>>>({
    method: 'DELETE',
  })

  function deleteWorkingDaysPlanned(id: WorkingDaysPlannedId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteWorkingDaysPlanned,
  }
}

export {
  useGetWorkingDaysPlanned,
  useCreateWorkingDaysPlanned,
  useUpdateWorkingDaysPlanned,
  useDeleteWorkingDaysPlanned,
}
