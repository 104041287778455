
import { computed, defineComponent, reactive, ref } from '@vue/composition-api'
import { cloneDeep } from 'lodash-es'
import { mdiSync, mdiPlus } from '@mdi/js'

import CommonTableView from '@/components/common/CommonTableView.vue'

import { useNotify } from '@/store'

import { useFilter } from '@/composables/useFilter'

import { setSort } from '@/utils/manipulateTableSort'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { useDeleteProductIncrement, useGetProductIncrements } from '@/api/productIncrement'

import { ProductIncrementOutput } from '@/api/types/productIncrement'
import { Rights } from '@/api/types/right'
import { OptionalFiltersParam } from '@/composables/types/useFilter'

export default defineComponent({
  name: 'ProductIncrements',
  components: {
    CommonTableView,
    AddEditProductIncrementDialog: () =>
      import('@/views/planning/views/timePlanning/components/AddEditProductIncrementDialog.vue'),
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
  },

  setup(_, { root }) {
    const TABLE_HEADERS = [
      {
        text: root.$t('planning.timePlanning.components.productIncrement.table.col.title.nr'),
        value: 'nr',
        sortable: true,
      },
      {
        text: root.$t('planning.timePlanning.components.productIncrement.table.col.title.start'),
        value: 'start',
        sortable: true,
      },
      {
        text: root.$t('planning.timePlanning.components.productIncrement.table.col.title.end'),
        value: 'end',
        sortable: true,
      },
      {
        text: root.$t('planning.timePlanning.components.productIncrement.table.col.title.actions'),
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const { addNotification } = useNotify()

    const {
      exec: getProductIncrements,
      data: productIncrements,
      isLoading,
      paginationResponse,
    } = useGetProductIncrements()

    const isEditMode = ref(false)

    const isAddEditModalOpen = ref(false)

    const productIncrementToEdit = ref<null | ProductIncrementOutput>(null)

    function onProductIncrementEdit(_, { item: productIncrement }: { item: ProductIncrementOutput }): void {
      if (hasSufficientRights(Rights.PRODUCT_INCREMENT_INTERVAL_UPDATE)) {
        isEditMode.value = true

        isAddEditModalOpen.value = true

        productIncrementToEdit.value = { ...productIncrement }
      }
    }

    const isDeleteModalOpen = ref(false)
    const productIncrementToDelete = ref<null | ProductIncrementOutput>(null)

    const { deleteProductIncrement: deleteProductIncrementXhr } = useDeleteProductIncrement()

    async function deleteProductIncrement(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await deleteProductIncrementXhr(productIncrementToDelete.value!.id)

      addNotification({
        text: root.$t('planning.timePlanning.components.productIncrement.delete.message') as string,
        type: 'success',
      })

      isDeleteModalOpen.value = false

      debouncedCb.value()
    }

    function onProductIncrementDelete(productIncrement: ProductIncrementOutput): void {
      productIncrementToDelete.value = cloneDeep(productIncrement)

      isDeleteModalOpen.value = true
    }

    function onCloseAddEditDialog(): void {
      isAddEditModalOpen.value = false

      productIncrementToEdit.value = null

      isEditMode.value = false
    }

    const { debouncedCb, vuetifyTableOptions, paginationParams } = useFilter([], init)
    setSort(vuetifyTableOptions, { by: 'nr', desc: false })

    const totalProductIncrement = computed(() => paginationResponse.value.totalElements)

    async function init(filterFieldsObject?: OptionalFiltersParam): Promise<void> {
      await getProductIncrements({ params: { ...paginationParams.value, ...filterFieldsObject } })
    }
    return reactive({
      icons: {
        mdiSync,
        mdiPlus,
      },
      constants: {
        Rights,

        TABLE_HEADERS,
      },
      state: {
        productIncrements,
        isLoading,

        isDeleteModalOpen,

        isEditMode,
        isAddEditModalOpen,
        productIncrementToEdit,

        totalProductIncrement,
        vuetifyTableOptions,
      },
      functions: {
        debouncedCb,

        deleteProductIncrement,

        hasSufficientRights,
      },
      listeners: {
        onCloseAddEditDialog,
        onProductIncrementEdit,
        onProductIncrementDelete,
      },
    })
  },
})
