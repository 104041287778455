import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'
import { PromiseType } from '@/utils/types/PromiseType'
import {
  ProductIncrementId,
  ProductIncrementInput,
  ProductIncrementOutput,
  UseCreateProductIncrement,
  UseDeleteProductIncrement,
  UseGetProductIncrements,
  UseUpdateProductIncrement,
} from './types/productIncrement'

const VERSION = 'v1'
const RESOURCE = 'product-increment-intervals'

function useGetProductIncrements(): UseGetProductIncrements {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetProductIncrements['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
  })
  return axios
}

function useCreateProductIncrement(): UseCreateProductIncrement {
  const axios = useAxios<PromiseType<ReturnType<UseCreateProductIncrement['createProductIncrement']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createProductIncrement(data: ProductIncrementInput): Promise<ProductIncrementOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createProductIncrement,
  }
}

function useUpdateProductIncrement(): UseUpdateProductIncrement {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateProductIncrement['updateProductIncrement']>>>({
    method: 'PUT',
  })

  function updateProductIncrement(
    id: ProductIncrementId,
    data: ProductIncrementOutput
  ): Promise<ProductIncrementOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateProductIncrement,
  }
}

function useDeleteProductIncrement(): UseDeleteProductIncrement {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteProductIncrement['deleteProductIncrement']>>>({
    method: 'DELETE',
  })

  function deleteProductIncrement(id: ProductIncrementId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteProductIncrement,
  }
}

export { useGetProductIncrements, useCreateProductIncrement, useUpdateProductIncrement, useDeleteProductIncrement }
