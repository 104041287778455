var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CommonTableView', {
    attrs: {
      "headers": _vm.constants.TABLE_HEADERS,
      "items": _vm.state.workingDaysPlanned,
      "loading": _vm.state.isLoading,
      "with-info": false,
      "options": _vm.state.vuetifyTableOptions,
      "server-items-length": _vm.state.totalWorkingDaysPlanned
    },
    on: {
      "update:options": [function ($event) {
        return _vm.$set(_vm.state, "vuetifyTableOptions", $event);
      }, function ($event) {
        return _vm.functions.debouncedCb();
      }],
      "dblclick:row": _vm.listeners.onWorkingDaysPlannedEdit,
      "click:delete": function clickDelete($event) {
        return _vm.listeners.onWorkingDaysPlannedDelete($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "table-header",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "flex ma-0",
          attrs: {
            "align": "center"
          }
        }, [_c('span', {
          staticClass: "mr-auto text-h5"
        }, [_vm._v(" " + _vm._s(_vm.$t("planning.timePlanning.components.workingDaysPlanned.title")) + " ")]), _c('v-text-field', {
          staticClass: "flex-grow-0 ml-auto",
          attrs: {
            "clearable": "",
            "label": _vm.$t('planning.timePlanning.components.workingDaysPlanned.filter.year'),
            "type": "number",
            "step": "1",
            "rules": [].concat(_vm.functions.isYear)
          },
          model: {
            value: _vm.state.FILTER_FIELDS[0].value,
            callback: function callback($$v) {
              _vm.$set(_vm.state.FILTER_FIELDS[0], "value", $$v);
            },
            expression: "state.FILTER_FIELDS[0].value"
          }
        }), _vm.functions.hasSufficientRights(_vm.constants.Rights.WORKING_DAYS_PLANNED_CREATE) ? _c('v-btn', {
          staticClass: "rounded-lg",
          attrs: {
            "color": "ml-auto primary",
            "elevation": "0"
          },
          on: {
            "click": function click($event) {
              _vm.state.isAddEditModalOpen = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" " + _vm._s(_vm.$t('planning.timePlanning.components.workingDaysPlanned.actions.addWorkingDaysPlanned')) + " ")], 1) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "item.actions.delete",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.WORKING_DAYS_PLANNED_DELETE) ? _c('div') : _vm._e()];
      },
      proxy: true
    }], null, true)
  }, [_vm.state.isAddEditModalOpen ? _c('AddEditWorkingDaysPlannedDialog', {
    attrs: {
      "is-edit-mode": _vm.state.isEditMode,
      "working-days-planned-to-edit": _vm.state.workingDaysPlannedToEdit
    },
    on: {
      "added-edited": function addedEdited($event) {
        return _vm.functions.debouncedCb();
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDialog();
      }
    },
    model: {
      value: _vm.state.isAddEditModalOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditModalOpen", $$v);
      },
      expression: "state.isAddEditModalOpen"
    }
  }) : _vm._e(), _c('CommonDeleteDialog', {
    on: {
      "cancel": function cancel($event) {
        _vm.state.isDeleteModalOpen = false;
      },
      "delete": function _delete($event) {
        return _vm.functions.deleteWorkingDaysPlanned();
      }
    },
    model: {
      value: _vm.state.isDeleteModalOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isDeleteModalOpen", $$v);
      },
      expression: "state.isDeleteModalOpen"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('planning.timePlanning.components.workingDaysPlanned.deleteWorkingDaysPlanned.text')) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }